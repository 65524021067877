.impact-container {
  margin: 4rem 6rem;
  text-align: start;
}
 .cent{
  align-items: center;
  margin: auto;
 }
.impact-container h1 {
  padding-bottom: 1rem;
}
 a {
  text-decoration: none !important;
  color: red;
}

.impact-container .row-images .image-text img{
  border-radius: 10px;
  width: 100%;
}

.row-images .image-text p{
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
}
.impact-container p {
  padding-bottom: 2rem;
}
ul {
  padding-left: 40px;
}

.row-images{
  display: flex !important;
  justify-content: space-between !important;
}

.row-images .image-text{
  width: 30% !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}
.sosh{
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 50px !important;
}

@media screen and (max-width: 850px) {
  .impact-container {
    margin: 4rem 2rem;
  }
  .row-images{
    flex-direction: column !important;
  }
  .row-images .image-text{
    width: 90% !important;
    align-items: center !important;
    margin-bottom: 30px;
  }
  .cent img{
    width: 250px !important;
    height: 250px !important;

  }
  .sosh{
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  .sosh .wert{
    margin-bottom: 50px !important;
  }
}
