.about-container {
  margin: 4rem 6rem;
  text-align: start;
}

.about-container h1 {
  padding-bottom: 1rem;
}

.about-container p {
  padding-bottom: 2rem;
}
.hide {
  display: none;
}

.myDIV:hover .hide {
  display: block !important;
  color: red;
}
pre { 
  text-align: left;
  white-space: pre-line;
  white-space: pre-wrap;       
  white-space: -moz-pre-wrap;  
  white-space: -pre-wrap;      
  white-space: -o-pre-wrap;    
  word-wrap: break-word;       
}

pre .b{
  font-weight: bold;
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
}
