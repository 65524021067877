

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-images{
  display: flex !important;
  justify-content: space-between !important;
}

.clients {
  width: 100%;
  margin: auto;
  align-items: center;

}

.row-images .image-text{
  width: 30% !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}

.row-images .image-tex{
  width: 30% !important;
}

.row-images .image-tex img{
  border-radius: 10px;
}

.row-images .image-core{
  width: 20% !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}

.row-images .image-core img{
  position: absolute;
  left: 100px;
  border-radius: 10px;
}

.row-images .row-1 img{
  position: absolute !important;
  translate: -120px -100px;
  width: 400px;
  height: 400px;
  border-radius: 10px;
}

.row-images .image-core p{
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
}

.row-images .row-1{
  width: 40% !important;
}



.row-images .row-1 p{
  font-size: 1.2rem;
  margin: auto;
  width: 70%;
  padding-top: 80px;
  padding-left: 150px;
}



.row-images .image-text img{
  border-radius: 10px;
  width: 400px;
}

.row-images .image-text p{
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
}





@media screen and (max-width: 850px) {
  .destination {
    margin: 4rem 2rem;
  }
  .des-text span {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
  .row-images .row-1{
    width: 90% !important;
  }
  .row-images .row-1 img{
    display: none;
    width: 200px !important;
    height: 200px !important;
    border-radius: 10px;
  }
  .row-images .row-1 p{
    font-size: 1.2rem;
    margin: auto;
    width: 90%;
    padding-top: 10px;
    padding-left: 10px;
  }

  .row-images{
    flex-direction: column !important;
  }
  .row-images .image-text{
    width: 90% !important;
    align-items: center !important;
    margin-bottom: 30px;
  }

  .row-images .image-tex{
    width: 90% !important;
    align-items: center !important;
    margin-bottom: 30px;
  }

  .row-images .image-core{
    width: 95% !important;
    margin-bottom: 30px;
  }

  .first-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
  }
  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4.5rem;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .container img {
    width: 500px !important;
    max-width: 100%;
    max-height: 100%;
  }

  .des-text {
    width: 100%;
  }

  .image {
    width: 100%;
    margin: 0.8rem 0;
  }
  .image img {
    height: 250px;
  }
}

@media screen and (max-width: 850px) {
  .images img{
    width: 200px !important;
    height: 200px;
    border-radius: 50%;
  }
}