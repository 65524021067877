.hero {
  width: 100%;
  height: 100vh;
  position: relative;
}

.hero-mid {
  width: 100%;
  height: 80vh;
  position: relative;
}

.hero-mid h1 {
  padding-top: 4rem !important;
}

img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: #9f509f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text p {
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;
  margin-bottom: 10px;
}

.hero-text .show {
  text-decoration: none;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #222;
}

.hero-text .hide {
  display: none;
}

.hero-text .show:hover {
  background-color: #6565d6;
}

@media screen and (max-width: 555px) {

  .hero {
    height: 30vh !important;
  }

 
  .hero-mid img {
    width: 100% !important;
    height: 90% !important;
    object-fit: fill !important;
  }
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
  }
  .hero-mid  {
    height: 30vh !important;
  }
  .hero-text h1 {
    padding: 10px 20px;
    font-size: 2.2rem;
  }
  .hero-mid h1 {
    padding-top: 4rem !important;
    font-size: 40px !important;
  }

  .hero-text p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }

  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
    font-size: 1rem;
  }
}
