.footer {
  padding: 4rem 6rem;
  background-color: #000;
  color: #fff;
}

.top {
  display: flex; 
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo1 {
  width: 175px;
}

.top i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}
.top i:hover {
  color: #7d7dda;
}

.bottom {
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
}

.bottom a {
  text-decoration: none;
  color: #fafafa !important;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

.footer a {
  text-decoration: none;
  color: #e34a2f;
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 1rem 2rem;
    height: 100%;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }
  .bottom a {
    font-size: 1rem;
  }

  .bottom h4 {
    font-size: 1.1rem;
    padding: 1rem 0 0.8rem 0;
  }
  .bottom div {
    width: 100%;
    align-items: center;
  }
}
