.form-container {
  margin-top: -100px !important;
    color: #2a2a2a;
    align-items: center !important;
  }
  
  .form-container form {
    padding-top:6rem;
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;
  }

  .contact-container .text{
    width: 400px;
   
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

select {
    
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
  }

  .contact-container .text .socials{
    display: inline-flex;
    
    margin: auto !important;
  }

  .contact-container .text .socials a{
    font-size: 30px;
    margin: auto !important;
    padding-left: 40px;

  }

  h2{
  font-weight: bold;
  border-bottom: 5px solid black;
  width: 15%;
  }


  
  .form-container input {
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
  }
  
  .form-container textarea {
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border: 1px solid #2a2a2a;
  }
  
  .form-container button {
    display: inline;
    font-size: 1rem;
    margin: auto;
    width: 140px;
    height: auto;
    border-radius: 10px;
  }
  
  .form-container button:hover {
    background-color: #e2492f !important;
  }
  
  @media screen and (max-width: 850px) {
    .form-container {
      margin: 4rem 2rem;
      margin-top: 0!important;
    }

    h2{
      width: 35%;
      }
  
    .form-container form {
      padding-top: 2rem;
      width: 95%;
    }

    .footer{
      flex-direction: column-reverse !important;
    }
  }
  